import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import renderBlock from '../utils/render-block'
import SEO from '../components/seo'

class BasicTemplate extends Component {

  render() {
    const { page } = this.props.data
    const content = page.acf.content_blocks_page || []
    return (
      <>
        <SEO title={he.decode(page.yoast_meta.yoast_wpseo_title)} description={page.yoast_meta.yoast_wpseo_metadesc} />
        { content.map((el, i) => renderBlock(el.__typename, el, i, this.props)) }
      </>
    )
  }
}

export default BasicTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    page: wordpressPage(id: {eq: $id}) {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      title
      acf {
        content_blocks_page {
          __typename
          ... on WordPressAcf_landing_splash {
            homepage
            heading {
              title
              background {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_small_split_content {
            title
            content
            link_text
            link_url
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_video_area {
            title
            content
            video
            video_poster {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            testimonials {
              testimonial {
                acf {
                  name
                  location
                  customer_type
                  testimony
                }
              }
            }
          }
          ... on WordPressAcf_intro_content_half {
            title
            subtitle
            content
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_simple_title {
            title
            background {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_icon_text_carousel {
            items {
              icon {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              title
              content
            }
          }
          ... on WordPressAcf_big_testimonial {
            testimonial {
              acf {
                name
                location
                customer_type
                testimony
              }
            }
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            linkedin
          }
          ... on WordPressAcf_text_content {
            title
            subtitle
            body
          }
          ... on WordPressAcf_contact {
            heading_title
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            form_title
          }
        }
      }
    }
  }
`
